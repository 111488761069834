<template>
  <section
      id="page-market"
      class="page"
  >
    <b-overlay
        :show="loading"
        no-center
        variant="transparent"
        rounded="sm"
    >
      <template #overlay>
        <loading-spinner />
      </template>

      <b-row>
        <b-col sm="12">
          <div class="title">
            <div class="d-flex justify-content-between">
              <div>
                {{ $t('Market') }}
              </div>

              <b-button
                  style="width: 180px"
                  size="sm"
                  variant="success"
                  :disabled="loading"
                  @click="modalCouponCode = true"
              >
                {{ $t('Use Coupon') }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row
          v-if="products.length"
          class="diamonds pl-05"
      >
        <b-col sm="12">
          <div class="d-flex justify-content-between">
            <h2>
              {{ $t('Credits') }}
            </h2>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <div class="header-credits">
                <div class="diamond-count d-flex">
                  <div class="diamond">
                    <b-img
                        :src="iconDiamonds1"
                        width="20"
                    />
                  </div>
                  <div class="diamond-count-text">
                    <span class="remaining">{{ $t('Balance') }}</span>
                    <div>
                      {{ credits }} {{ $t('Diamonds') }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="creditsCharged">
                <p class="fs-12 fw-300 mb-0">
                  *1 {{ $t('Minute') }} {{ $t('with voice') }} = {{ creditsCharged.voiceCall }} {{ $t('Diamonds') }}
                </p>
                <p class="fs-12 fw-300 mb-0">
                  *1 {{ $t('Minute') }} {{ $t('with video') }} = {{ creditsCharged.videoCall }} {{ $t('Diamonds') }}
                </p>
                <p
                    v-if="false"
                    class="fs-12 fw-300"
                >
                  *1 {{ $t('Minute') }} {{ $t('with random video call') }} = {{ creditsCharged.randomVideoCall }} {{ $t('Diamonds') }}
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
            v-for="product in products"
            :key="product.productId"
            class="product"
            xl="2"
            lg="3"
            md="4"
            sm="6"
            cols="6"
        >
          <router-link :to="{name: 'buy', params: {productId: product.productId}, query: appliedBonusCode ? {code: appliedBonusCode, ratio: couponCodeBonusRatio} : null, }">
            <b-card
                tag="div"
                class="diamond"
                @click="gtmSelectProduct(product.productId)"
            >
              <div class="diamond-header">
                <b-img
                    :src="iconDiamonds3New"
                    style="margin-left: -8px"
                />
              </div>
              <div class="diamond-body">
                <div class="diamond-piece">
                  {{ numberFormat(product.credits) }}
                  {{ couponCodeBonusRatio ? `+ ${numberFormat(product.credits * couponCodeBonusRatio)}` : null }}
                </div>
                <div class="diamond-price">
                  {{ product.currency === 'USD' ? '$' : '&#8378;' }} {{ product.amount }}
                </div>
              </div>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
      <b-row
          v-if="chatProducts.length"
          class="diamonds pl-05"
      >
        <b-col sm="12">
          <h2>
            {{ $t('Message Packages') }}
          </h2>
        </b-col>
        <b-col
            v-for="chatProduct in chatProducts"
            :key="chatProduct.productId"
            class="product"
            xl="2"
            lg="3"
            md="4"
            sm="6"
            cols="6"
            @click="openBuyChatProduct(chatProduct)"
        >
          <b-card
              tag="div"
              class="diamond"
          >
            <div class="diamond-header">
              <b-img
                  :src="iconChat4"
                  height="36"
              />
            </div>
            <div class="diamond-body">
              <div class="diamond-piece">
                {{ chatProduct.duration == 1 ? $t('{day} day pack', {day: chatProduct.duration}) : $t('{day} days pack', {day: chatProduct.duration}) }}
              </div>
              <div class="diamond-description">
                {{ $t('Unlimited texting for {day} days', {day: chatProduct.duration}) }}
              </div>
              <div class="diamond-price">
                {{ numberFormat(chatProduct.credits) }} <b-img
                  :src="iconDiamonds1"
                  height="18"
              />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row
          v-if="false"
          class="pl-05"
      >
        <b-col sm="12">
          <h2>
            {{ $t('Special Offer') }}
          </h2>
        </b-col>
        <b-col
            xl="3"
            lg="4"
            md="6"
            sm="6"
            cols="6"
        >
          <b-card
              tag="div"
              class="special-offer"
          >
            <div class="special-offer-header bg-1">
              <div class="special-offer-type">
                {{ $t('Subscription') }}
              </div>
              <div class="special-offer-description">
                {{ $t('See who likes you and more') }}...
              </div>
            </div>
            <div class="special-offer-body">
              <div class="special-offer-name">
                {{ $t('VIP Membership') }}
              </div>
              <div class="subscribe-button">
                {{ $t('Subscribe') }}
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col
            xl="3"
            lg="4"
            md="6"
            sm="6"
            cols="6"
        >
          <b-card
              tag="div"
              class="special-offer"
          >
            <div class="special-offer-header bg-2">
              <div class="special-offer-type">
                {{ $t('Subscription') }}
              </div>
              <div class="special-offer-description">
                {{ $t('See who likes you and more') }}...
              </div>
            </div>
            <div class="special-offer-body">
              <div class="special-offer-name">
                {{ $t('VIP Membership') }}
              </div>
              <div class="subscribe-button">
                {{ $t('Subscribe') }}
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row
          v-if="false"
          class="pl-05"
      >
        <b-col sm="12">
          <h2 class="mb-1">
            {{ $t('Subscription Packages') }}
          </h2>
        </b-col>
        <b-col
            xl="3"
            lg="4"
            md="6"
            sm="6"
            cols="6"
        >
          <b-card
              tag="div"
              class="subscription-package"
          >
            <div class="subscription-package-header">
              <div class="subscription-package-icon">
                <b-img :src="iconVip" />
              </div>
              <div class="subscription-package-text">
                <div class="subscription-package-title">
                  <span class="subscription-package-name">
                    {{ $t('VIP Membership') }}
                  </span>
                  <span class="subscription-package-period">
                    / 1 {{ $t('Month') }}
                  </span>
                </div>
                <div class="subscription-package-description">
                  {{ $t('This package is just for you to enjoy unlimited benefits') }}
                </div>
                <div class="mt-1 subscribe-button">
                  {{ $t('Subscribe') }}
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col
            xl="3"
            lg="4"
            md="6"
            sm="6"
            cols="6"
        >
          <b-card
              tag="div"
              class="subscription-package"
          >
            <div class="subscription-package-header">
              <div class="subscription-package-icon">
                <b-img :src="iconLounge" />
              </div>
              <div class="subscription-package-text">
                <div class="subscription-package-title">
                  <span class="subscription-package-name">
                    {{ $t('Lounge') }}
                  </span>
                  <span class="subscription-package-period">
                    / 1 {{ $t('Month') }}
                  </span>
                </div>
                <div class="subscription-package-description">
                  {{ $t('This package is just for you to enjoy unlimited benefits') }}
                </div>
                <div class="mt-1 subscribe-button">
                  {{ $t('Subscribe') }}
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col
            xl="3"
            lg="4"
            md="6"
            sm="6"
            cols="6"
        >
          <b-card
              tag="div"
              class="subscription-package"
          >
            <div class="subscription-package-header">
              <div class="subscription-package-icon">
                <b-img :src="iconDailyDiamond" />
              </div>
              <div class="subscription-package-text">
                <div class="subscription-package-title">
                  <span class="subscription-package-name">
                    {{ $t('Daily Diamond') }}
                  </span>
                  <span class="subscription-package-period">
                    / 1 {{ $t('Month') }}
                  </span>
                </div>
                <div class="subscription-package-description">
                  {{ $t('This package is just for you to enjoy unlimited benefits') }}
                </div>
                <div class="mt-1 subscribe-button">
                  {{ $t('Subscribe') }}
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <b-modal
        v-if="selectedChatProduct"
        v-model="modalBuyChatProduct"
        centered
        hide-header-close
        hide-footer
        size="md"
    >
      <div class="buy-product-modal">
        <h4>
          {{ $t('You get {product} for {diamond} diamonds', {diamond: numberFormat(selectedChatProduct.credits), product: selectedChatProduct.description}) }}
        </h4>
        <b-button
            class="mt-2 px-4"
            variant="primary"
            size="sm"
            @click="buyChatProduct"
        >
          <b-spinner
              v-if="loading"
              small
          />
          {{ $t('Buy') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-model="modalCouponCode"
        centered
        hide-header-close
        hide-footer
        hide-header
        size="sm"
    >
      <b-overlay
          :show="loading"
          no-center
          variant="transparent"
          rounded="sm"
      >
        <template #overlay>
          <loading-spinner />
        </template>
        <div
            class="d-flex justify-content-end fs-12 color-gray cursor-pointer"
            @click="modalCouponCode = false"
        >
          {{ $t('Close') }}
        </div>
        <b-row class="password-information text-center mb-2">
          <b-col md="12">
            <div class="d-flex justify-content-center align-items-center flex-column pt-2 py-2">
              <h5 class="fs-22 fw-600 text-center color-black">
                {{ $t('Use Coupon') }}
              </h5>
            </div>
          </b-col>
          <b-col sm="12">
            <validation-observer
                ref="form"
                #default="{invalid}"
            >
              <b-form @submit.prevent>
                <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Coupon Code')"
                      vid="code"
                      rules="required|min:4"
                  >
                    <b-input-group>
                      <b-form-input
                          v-model="couponCode"
                          name="couponCode"
                          :loading="loading"
                          :placeholder="$t('Enter Your Coupon')"
                      />
                    </b-input-group>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                    <div
                        class="mt-05 fs-12"
                        :class="couponCodeInfoColor"
                    >
                      <font-awesome-icon
                          icon="fa-solid fa-info-circle"
                      />
                      {{ couponCodeInfoText }}
                    </div>
                  </validation-provider>
                </b-form-group>

                <b-button
                    size="sm"
                    style="width: 110px"
                    variant="success"
                    :disabled="loading || invalid"
                    :loading="loading"
                    @click="applyCouponCode"
                >
                  {{ $t('Use') }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
    <b-modal
        v-model="modalCouponCodeSucceed"
        centered
        hide-header-close
        hide-footer
        hide-header
        size="sm"
    >
      <div
          class="d-flex justify-content-end fs-12 color-gray cursor-pointer"
          @click="modalCouponCodeSucceed = false"
      >
        {{ $t('Close') }}
      </div>
      <b-row>
        <b-col sm="12">
          <div class="d-flex flex-column justify-content-center text-center gap-12 mb-2">
            <div>
              <b-img
                  :src="iconDiamonds3"
                  width="140"
              />
            </div>
            <h1
                v-if="couponCodeBonusRatio"
                class="my-0"
            >
              {{ couponCodeBonusRatioText }}
            </h1>
            <div class="fs-20">
              {{ couponCodeResultText }}
            </div>
            <div v-if="!couponCodeBonusRatio">
              {{ $t('Have fun') }}!
            </div>
            <div
                v-else
                class="mt-05 fs-12 color-gray2"
            >
              <font-awesome-icon
                  icon="fa-solid fa-info-circle"
              />
              {{ $t('Your bonuses will be added to your package after your diamond package purchase.') }}
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BImg, BInputGroup, BModal, BOverlay, BRow, BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import {
  gtmTrackNewEvent, showNotify, throwDefaultError, numberFormat,
} from '@/helpers/helpers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const iconDiamonds1 = require('@/assets/images/icons/diamonds-1.svg')
const iconDiamonds2 = require('@/assets/images/icons/diamonds-2.svg')
const iconDiamonds3 = require('@/assets/images/icons/diamonds-3.svg')
const iconDiamonds3New = require('@/assets/images/icons/diamonds-3-new.svg')
const iconDiamonds4 = require('@/assets/images/icons/diamonds-4.svg')
const iconDiamonds5 = require('@/assets/images/icons/diamonds-5.svg')
const iconDiamonds6 = require('@/assets/images/icons/diamonds-6.svg')
const iconEnvelope1 = require('@/assets/images/icons/envelope-1.svg')
const iconEnvelope2 = require('@/assets/images/icons/envelope-2.svg')
const iconEnvelope3 = require('@/assets/images/icons/envelope-3.svg')
const iconEnvelope4 = require('@/assets/images/icons/envelope-4.svg')
const iconEnvelope5 = require('@/assets/images/icons/envelope-5.svg')
const iconEnvelope6 = require('@/assets/images/icons/envelope-6.svg')
const iconChat4 = require('@/assets/images/icons/chat-4.svg')
const iconVip = require('@/assets/images/icons/vip.png')
const iconLounge = require('@/assets/images/icons/lounge.png')
const iconDailyDiamond = require('@/assets/images/icons/daily-diamond.png')

export default {
  components: {
    BFormGroup,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BCol,
    BImg,
    BModal,
    BOverlay,
    BRow,
    BSpinner,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      appliedBonusCode: '',
      chatProducts: [],
      creditsCharged: null,
      couponCode: '',
      couponCodeBonusRatio: null,
      couponCodeBonusRatioText: '',
      couponCodeInfoColor: 'color-gray',
      couponCodeInfoText: this.$t('Coupon is single use, not reusable'),
      couponCodeResultText: '',
      couponCodeSucceed: false,
      iconDiamonds1,
      iconDiamonds2,
      iconDiamonds3,
      iconDiamonds3New,
      iconDiamonds4,
      iconDiamonds5,
      iconDiamonds6,
      iconEnvelope1,
      iconEnvelope2,
      iconEnvelope3,
      iconEnvelope4,
      iconEnvelope5,
      iconEnvelope6,
      iconChat4,
      iconVip,
      iconLounge,
      iconDailyDiamond,
      loading: false,
      modalBuyProduct: false,
      modalBuyChatProduct: false,
      modalCouponCode: false,
      modalCouponCodeSucceed: false,
      products: [],
      selectedChatProduct: null,
      selectedProduct: null,
      user: null,
    }
  },
  computed: {
    credits() {
      return this.$store.getters['auth/credits']
    },
  },
  created() {
    this.getProducts()

    this.getCreditsCharged()
  },
  mounted() {
    // app-content in arka planını değiştir
    this.changeAppContentBackground('#FEF9F9')
  },
  destroyed() {
    // app-content in arka planını değiştir
    this.changeAppContentBackground('#FFF')
  },
  methods: {
    applyCouponCode() {
      this.loading = true

      useJwt.couponCodeToAddDiamond({ code: this.couponCode })
          .then(response => {
            const data = response.data.body

            console.log('couponCodeToAddDiamond data', data)

            if (data.valid) {
              this.couponCodeBonusRatio = null
              this.couponCodeBonusRatioText = ''
              this.couponCodeResultText = this.$t('The diamonds defined in your voucher have been loaded. You can check your diamonds on my profile page.')
              this.couponCode = ''
              this.appliedBonusCode = ''
              this.modalCouponCode = false
              this.modalCouponCodeSucceed = true
              this.loading = true
              useJwt.getMe()
                  .then(response2 => {
                    this.user = response2.data.body
                    this.$store.dispatch('auth/updateUser', this.user)
                  })
                  .catch(error => {
                    throwDefaultError(this.$toast, error)
                  })
                  .finally(() => { this.loading = false })
            } else if (data.bonusRatio) {
              this.modalCouponCode = false
              this.couponCodeBonusRatio = data.bonusRatio
              this.couponCodeBonusRatioText = `%${data.bonusRatio * 100}`
              this.couponCodeResultText = this.$t('A bonus of {ratio} has been added to your coupon. You can continue shopping.', { ratio: `%${data.bonusRatio * 100}` })
              this.appliedBonusCode = this.couponCode
              this.couponCode = ''
              this.modalCouponCodeSucceed = true
            } else {
              // Kupon kodu geçerli değil
              this.couponCodeInfoColor = 'color-red-light'
              this.couponCodeInfoText = this.$t('You entered an incorrect or invalid coupon code. Check your details and try again. In case of problems, contact Boho Assistant.')
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    buyChatProduct() {
      this.loading = true
      useJwt.buyChatProduct({ productId: this.selectedChatProduct.productId })
          .then(() => {
            // eslint-disable-next-line no-underscore-dangle
            const _message = this.$t('You have successfully purchased product {product}', { product: this.selectedChatProduct.description })
            showNotify(this.$toast, _message, 'success')

            gtmTrackNewEvent('chatproductbuy', {
              category: 'ChatProductSelect',
              action: 'ChatProductBuySuccess',
              label: this.selectedChatProduct.productId,
            })

            setTimeout(() => {
              this.modalBuyChatProduct = false
              this.selectedChatProduct = null
            }, 11)
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)

            gtmTrackNewEvent('chatproductbuy', {
              category: 'ChatProductSelect',
              action: 'ChatProductBuyFail',
              label: this.selectedChatProduct.productId,
            })
          })
          .finally(() => { this.loading = false })
    },
    changeAppContentBackground(color) {
      setTimeout(() => {
        const appContent = document.querySelector('.app-content')
        if (appContent) {
          appContent.style.background = color
        }
      }, 11)
    },
    getCreditsCharged() {
      useJwt.creditsCharged()
          .then(response => {
            console.log('response', response)
            this.creditsCharged = response.data.body
          })
    },
    getDiamondImage(product) {
      if (product.credits < 601) {
        return this.iconDiamonds1
      } if (product.credits < 1001) {
        return this.iconDiamonds2
      } if (product.credits < 1501) {
        return this.iconDiamonds3
      } if (product.credits < 2001) {
        return this.iconDiamonds4
      } if (product.credits < 2501) {
        return this.iconDiamonds5
      }

      return this.iconDiamonds6
    },
    getEnvelopeImage(product) {
      if (product.credits < 2001) {
        return this.iconEnvelope2
      } if (product.credits < 5001) {
        return this.iconEnvelope3
      } if (product.credits < 10001) {
        return this.iconEnvelope5
      }

      return this.iconEnvelope6
    },
    getProducts() {
      this.loading = true

      // eslint-disable-next-line no-underscore-dangle
      const _getProducts = useJwt.getProducts()
      // eslint-disable-next-line no-underscore-dangle
      const _getChatProducts = useJwt.getChatProducts()
      // eslint-disable-next-line no-underscore-dangle
      const _getMe = useJwt.getMe()

      Promise.all([_getProducts, _getChatProducts, _getMe])
          .then(values => {
            this.products = values[0].data.body.filter(p => p.paymentMethod === 'credit_card')

            this.chatProducts = values[1].data.body

            this.user = values[2].data.body
            this.$store.dispatch('auth/updateUser', this.user)
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    gtmSelectProduct(id) {
      gtmTrackNewEvent('productselect', {
        category: 'Market',
        action: 'ProductSelect',
        label: id,
      })
    },
    numberFormat(value) {
      return numberFormat(value)
    },
    openBuyChatProduct(product) {
      this.modalBuyChatProduct = true
      this.selectedChatProduct = product

      gtmTrackNewEvent('chatproductselect', {
        category: 'ChatProductSelect',
        action: 'ChatProductSelect',
        label: product.productId,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#page-market {
  .product {
    cursor: pointer;
  }
  .row {
    margin-right: unset !important;
  }
  .title {
    display: block !important;
    width: 100% !important;
    margin-left: 8px;
    padding: 8px 8px 14px 0;
  }
}

.diamond {
  padding: 0;
  //border: 1px solid #F5EEF0;
  background: #fff;
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  font-family: 'Lexend', sans-serif !important;
  font-style: normal;
  overflow: hidden;
  color: #000000;

  .card-body {
    padding: 0;
  }

  .diamond-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    margin-top: 8px;
    //background-color: #FFE8AD;
  }
  .diamond-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-direction: column;

    .diamond-piece {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 16px;
      text-align: center;
    }

    .diamond-description {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: #999;
      margin-bottom: 16px;
      text-align: center;
      max-width: 180px;
    }

    .diamond-price {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      background: linear-gradient(270deg, #B921D2 8.57%, #E260C6 99.29%);
      border-radius: 100px;
      color: #fff;
      padding: 4px 12px;
      margin-bottom: 6px;
    }
  }
}

.special-offer {
  padding: 0;
  min-height: 300px;

  .card-body {
    padding: 0;
  }

  .bg-1 {
    background: url('../../assets/images/bg/special-offer-1.png') center center no-repeat
  }

  .bg-2 {
    background: url('../../assets/images/bg/special-offer-2.jpeg') center center no-repeat
  }

  .special-offer-header {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: end;
    background-size: cover;
    min-height: 260px;
    padding: 14px;

    .special-offer-type, .special-offer-description {
      color: #fff;
    }
    .special-offer-type {
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
    }
    .special-offer-description {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;

    }
  }

  .special-offer-body {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    align-items: center;

    .special-offer-name {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.subscription-package {
  padding: 0;

  .card-body {
    padding: 14px;
  }

  .subscription-package-header {
    display: flex;

    .subscription-package-icon {
      margin-right: 8px;
    }

    .subscription-package-text {
      .subscription-package-title {
        .subscription-package-name {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
        .subscription-package-period {
          font-weight: 300;
          font-size: 12px;
          line-height: 15px;
          opacity: 0.3;
        }
      }
      .subscription-package-description {
        margin-top: 4px;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        opacity: 0.3;
      }
    }
  }
  .subscription-package-body {
    margin-top: 18px;
    display: flex;
    justify-content: center;
  }
}

.subscribe-button {
  display: inline-block;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  background: #E2E4E9;
  border-radius: 100px;
  padding: 10px 12px;
  cursor: pointer;
}

.buy-product-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.header-credits {
  margin-left: 0;

  .diamond-count {
    margin-left: 1px;
  }

  @media only screen and (max-width: 576px){
    .diamond-count{
      display: flex;
    }
  }
}

.color-red-light {
  color: #FC668A !important;
}
</style>
